.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jumbotron {
  /*background-image: url(/static/img/desert.jpeg);*/
  background-image: url(/django_static/img/desert.jpeg);
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: cover;
  padding-top: 50px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 50px;
  border-radius: 50px;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}

.jumbotron div {
  background-color: #ffffff73;
  padding: 50px;
  text-align: center;
}
/* .jumbotron h1, p {
  padding: 50px;
  text-align: center;
} */



.btn-primary {
  color: black;
  background-color: #ff6600;
  border-color: #ff6600;
}

.participant-form {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.participant-form label {
  text-align: left;
  display: block;
}
.participant-form h2 {
  font-size: 20px;
  color: #000000;
  margin-bottom: 30px;
}
.participant-form input, .create textarea, .create select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}
.participant-form button {
  background: #ff6600;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

td button {
  background: #ff6600;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

button.nabu {
  background: #ff6600;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

button.innactive {
  background: #000000;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}